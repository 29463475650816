<template>
	<div>
		<div class="js-copy js-content" style="width: 1200px">
			<div class="js-title">
				<h2>版权声明</h2>
			</div>
			<div class="js-copy-copy">
				<p v-if="this.localLanguage != 2"
					>1、本网站刊载的所有内容，包括但不限于文字报道、图片、视频、图表、标志标识、商标、版面设计、专栏目录与名称、内容分类标准等，均受《中华人民共和国著作权法》、《中华人民共和国商标法》、《中华人民共和国专利法》及适用之国际公约中有关著作权、商标权、专利权以及或其它财产所有权法律的保护，相应的版权或许可使用权均属本网站所有。</p
				>
				<p v-else>
					1. All the contents published on this website, including but not
					limited to text reports, pictures, videos, charts, logos, trademarks,
					layout design, catalogue and name of columns, content classification
					standards, etc., are protected by the Copyright Law of the People's
					Republic of China, Trademark Law of the People's Republic of China,
					Patent Law of the People's Republic of China, and applicable
					international conventions on copyright, trademarks, patents, and other
					property ownership laws. The corresponding copyrights or licences
					belong to this website. Translated with www.DeepL.com/Translator (free
					version)</p
				>
				<p v-if="this.localLanguage != 2"
					>2、凡未经Meta
					Era书面授权，任何媒体、网站及个人不得转载、复制、重制、改动、展示或使用界面网站的局部或全部的内容或服务，或在非Meta
					Era网站所属服务器上建立镜像。如果已转载，请自行删除。同时，我们保留进一步追究相关行为主体的法律责任的权利。
				</p>
				<p v-else
					>2、Where without MetaEra written authorisation, any media, websites
					and individuals may not reproduce, duplicate, reproduce, alter,
					display or use part or all of the contents or services of the
					interface website, or establish a mirror image on a server that is not
					owned by Meta MetaEra's servers. If you have already reproduced,
					please remove yourself. At the same time, we reserve the right to
					further pursue the legal responsibility of the relevant actors.
				</p>
				<p v-if="this.localLanguage != 2"
					>3、我们希望与各媒体合作，签订著作权有偿使用许可合同。凡侵犯Meta
					Era版权等知识产权的，MetaEra必依法追究其法律责任。</p
				>
				<p v-else
					>3、We hope to cooperate with the media and sign a copyright paid use
					licence contract. Any infringement of MetaEra copyright and other
					intellectual property rights, MetaEra will pursue its legal
					responsibility in accordance with the law.</p
				>
				<p v-if="this.localLanguage != 2"
					>4、已经本网站授权使用的，应按照授权合同所规定条款使用。</p
				>
				<p v-else
					>4. If you have been authorised to use this website, you should use it
					in accordance with the terms and conditions stipulated in the
					authorisation contract.</p
				>

				<p v-if="this.localLanguage != 2"
					>5、本网站摘录或转载的属于第三方的信息，目的在于传递更多信息，并不代表本网站赞同其观点和对其真实性负责，转载信息版权属于原媒体及作者。如其他媒体、网站或个人擅自转载使用，请自负版权等法律责任。</p
				>
				<p v-else
					>5, this website excerpts or reproduced information belonging to third
					parties, the purpose is to convey more information, and does not mean
					that this website agrees with its views and is responsible for the
					authenticity of the reproduced information copyright belongs to the
					original media and the author. If other media, websites or individuals
					unauthorised reproduction and use, please bear the copyright and other
					legal responsibilities. Translated with www.DeepL.com/Translator (free
					version)</p
				>
			</div>
		</div>
	</div>
</template>

<script>
import { eventBus } from "../components/pc/leftTab.vue";
export default {
	name: "",
	data() {
		return {
			localLanguage: 1,
		};
	},
	created() {
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
	},
	mounted() {
		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "简体中文") {
				this.localLanguage = 0;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 1;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
	},
};
</script>

<style scoped>
.js-copy {
	background: #fff;
	margin: 0 auto;
}

.js-copy .js-title {
	padding: 30px 100px;
}

.js-copy .js-title:hover {
	color: #333;
}

.js-copy h2 {
	width: 100%;
	text-align: center;
	padding: 20px 0;
	height: 40px;
	font-size: 30px;
}

.js-copy .js-copy-copy {
	font-size: 16px;
	padding: 0 200px 50px;
	line-height: 36px;
}

.js-copy .js-copy-copy p {
	padding: 10px 0;
}
</style>
